/**
 * 获取设备模型列表
 */
export const getDeviceModelsApi = () => useGet<DeviceModel[]>('/deploy/device-model/list')
/**
 * 获取所有的设备模型规则表达式
 */
export const getDeviceModelExpressionApi = (deviceModelCode: string) => useGet('/deploy/device-model-expression/list', { deviceModelCode })

/**
 * 根据id获取设备模型
 */
export const getDeviceModelByIdApi = (id: number) => useGet(`/deploy/device-model/${id}`)

/**
 * 增加设备模型
 */
export const addDeviceModelApi = (param: any) => usePost('/deploy/device-model', param)

/**
 * 删除设备
 */
export const deleteDeviceModelByIdApi = (id: number) => useDelete(`/deploy/device-model/${id}`)

/**
 * 编辑设备模型
 */
export const editDeviceModelApi = (param: Partial<DeviceModel>) => usePut('/deploy/device-model', param)

/**
 * 获取所有的设备状态的规则定义列表
 */
export const getDeviceModelStateApi = (deviceModelCode: any) => useGet('/deploy/device-model-state/list', { deviceModelCode })

/**
 * 新增设备模型的规则表达式
 */
export const addDeviceModelRulesExpressionApi = (data: any) => usePost('/deploy/device-model-expression', data)

/**
 * 新增设备状态的规则定义
 */
export const addDeviceModelStatusApi = (data: any) => usePost('/deploy/device-model-state', data)

/**
 * 修改设备模型的规则表达式
 */
export const updateDeviceModelRulesExpressionApi = (data: any) => usePut('/deploy/device-model-expression', data)

/**
 * 修改设备状态的规则定义
 */
export const updateDeviceModelStatusApi = (data: any) => usePut('/deploy/device-model-state', data)

/**
 * 删除设备模型的规则表达式
 */
export const deleteDeviceModelRulesExpressionApi = (id: string) => useDelete(`/deploy/device-model-expression/${id}`)

/**
 * 删除设备状态的规则定义
 */
export const deleteDeviceModelStatusApi = (id: string) => useDelete(`/deploy/device-model-state/${id}`)

/**
 * 获取设备能力模型列表
 */
export async function getDeviceAbilityModelListApi(deviceModelCode: string) {
  const devideModelAbilities = await useGet('/deploy/device-model-ability/list', { deviceModelCode })
  devideModelAbilities.forEach((item: any) => {
    const detail = item.detail
    if (detail && typeof detail === 'string') {
      item.detail = JSON.parse(detail)
    }
  })

  return devideModelAbilities
}

/**
 * 获取所有的设备模型
 */
export const getAbilityModeThingModelApi = (params: any) => useGet('/deploy/device-model-ability/thing-model', params)

/**
 * 新增设备能力模型
 */
export const addDeviceAbilityModelApi = (data: any) => usePost('/deploy/device-model-ability', data)

/**
 * 删除设备能力模型
 */
export const deleteDeviceAbilityModelApi = (id: number) => useDelete(`/deploy/device-model-ability/${id}`)

/**
 * 更新设备能力模型
 */
export const updateDeviceAbilityModelApi = (deviceAbilityModel: any) => usePut('/deploy/device-model-ability', deviceAbilityModel)

/**
 * 查询指定设备模型的所有能力（简单哈希）
 */

export const getDeviceModelAbilityHashSimpleApi = (params: any) => useGet('/deploy/device-model-ability/hash-simple', params)
